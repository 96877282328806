.caption {
    position: relative;
    margin-bottom: 6em;
    padding-top: 0 !important;
    @media (min-width: @medium ) {
        position: unset;
        order: 2;
        margin-bottom: 0 !important;
    }
}

.caption:after {
    content: "";
    position: absolute;
    top: -3em; right: -5vw;
    padding: 3em 0;
    background: @gradient;
    background: @gradient2;
    width: calc(80% - 2.5em);
    height: 100%;
    z-index: -1;
    border-radius: @radius 0 0 @radius;
    @media (min-width: @medium ) {
        width: calc(50% - 5em);
        padding: 0;
        top: 0; right: 0;
    }
}

.statistics img {
    height: 100%;
    border-radius: @radius-small;
    max-height: 300px;
    @media (min-width: @medium ) {
        max-height: 420px;
    }
}



.caption-image {
    height: 100%;
}