
// reset

.button {
	outline:0;
}

// basic button style

.button {
	text-decoration:none;
	display:inline-block;
}

.simple-cta::after {
    display: block;
    content: "";
    float: left;
    height: 2px;
    width: 100%;
    background: #fff;
	transition: 0.3s all ease-in-out;
	position: relative;
	right: 0;
	margin-top: 5px;
}

.text-red.simple-cta::after {
	background: @red;
}

.simple-cta:hover::after {
	width: 80%;
	right: 0;
}

.button-fill {
	padding: .75em 1.5em;
	border-radius: 6px;
	transition: 0.3s all ease-in-out;
	background: @red;
	&:hover {
		background: @darkred;
	}
}

// button types

.button--primary {}

.button--secondary {}


// modifiers

.button--block {
	display:block;
}