
// global
@import "global/animations";
@import "global/body";
@import "global/breakpoints";
@import "global/debug";
@import "global/reset";
@import "global/typography";
@import "global/background";

// Vendor
@import "./vendor/mediabox.css";

// components
@import "components/a";
@import "components/button";
@import "components/breadcrumbs";
@import "components/grid";
@import "components/icon";
@import "components/page-width";
@import "components/spacing";
@import "components/header";
@import "components/hero";
@import "components/text-image";
@import "components/statistics";
@import "components/testimonial";
@import "components/support";
@import "components/latest-articles";
@import "components/footer";
@import "components/banner";
@import "components/team";
@import "components/isotope";
@import "components/video";
@import "components/cards";
@import "components/text-carousel";
@import "components/news";
@import "components/map";
@import "components/contact-form";
@import "components/schools";

// main
@import "mixins";
@import "utility";
@import "variables";

// overrides
@import "overrides";