.fill--red {
    background: @red;
}

.fill--white {
    background: @white;
}

.fill--dark-gray {
    background: @darkgray;
}