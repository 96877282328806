.text-carousel {
    @media (min-width: @medium) {
        margin-top: -2em;
        margin-left: -2em;
    }
}

.text-carousel .slick-slide {
    @media (min-width: @medium) {
        padding: 2em;
    }
}