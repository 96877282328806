
.video-row__image {
    max-width: 700px;
    z-index: 10;
    &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,.4);
        z-index: 15;
        position: absolute;
        top: 0; left: 0;
    }
}

.video-row__background {
    height: calc(50% + 2.5em);    
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    z-index: 5;
}

.video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    width: 80px;
}

.video-row__media a {
    padding: 0 5vw !important;
    display: block;
}