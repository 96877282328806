

// Featured image

.news-featured {
    border-radius: 0 0 0 @radius;
    overflow: hidden;
    margin-left: 5vw;
    height: 320px;
    @media (min-width: @medium) {
        height: 500px;
        margin-left: 20vw;
    }
}

.news-quote {
    border-radius: 0 0 0 @radius-small;
    overflow: hidden;
    & p {
        font-weight: 500;
    }
}

.news-image {
    height: 320px;
    @media (min-width: @medium) {
        height: 500px;
    }
}