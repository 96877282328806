// custom font

	// @font-face {
	//     font-family: '_____';
	//     src: url('./../fonts/____.eot?#iefix') format('embedded-opentype'), 
	//          url('./../fonts/____.woff') format('woff'), 
	//          url('./../fonts/____.ttf') format('truetype'),
	//          url('./../fonts/____.otf') format('otf'), 
	//          url('./../fonts/____.svg#____') format('svg');
	//     font-weight: normal;
	//     font-style: normal;
	// }

	@font-face {
		font-family: 'Archer';
		src: url('/wp-content/themes/next-step/assets/fonts/Archer-Book.woff2') format('woff2'),
			url('/wp-content/themes/next-step/assets/fonts/Archer-Book.woff') format('woff'),
			url('/wp-content/themes/next-step/assets/fonts/Archer-Book.ttf') format('truetype');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Archer';
		src: url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Semibold.woff2') format('woff2'),
			url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Semibold.woff') format('woff'),
			url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Semibold.ttf') format('truetype');
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Archer';
		src: url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Bold.woff2') format('woff2'),
			url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Bold.woff') format('woff'),
			url('/wp-content/themes/next-step/assets/fonts/ArcherPro-Bold.ttf') format('truetype');
		font-weight: 700;
		font-style: normal;
		font-display: swap;
	}

	@import url("https://use.typekit.net/bhg1dqv.css");

// reset all headings to basic text styles. 
// use semantic <h1>, <h2> elements where appropriate based on content flow.
// use the .heading-1, .heading-2, etc. classes to style headings visually.

	h1, h2, h3, h4, h5, h6 {
		font-family:@main;
		font-weight: 500;
		font-size:inherit;
		-webkit-font-smoothing: antialiased;
	}


// base global text styles

	html {
		font-family:@body;
		
		// use unitless line-height
		line-height:1.4; 
	}

	// the main text-based block elements in
	// use for any paragraph-style text

	p,
	li,
	table,
	blockquote,
	pre,
	address,
	dd, dt, dl, 
	caption, details, summary {
		font-size:1em;
		font-weight: 300;
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
	}


// custom headings

	.heading-1 {
		font-size:2.2em;
		line-height: 1em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {}
		@media(min-width:@large) {
			font-size: 3.5em;
			line-height: 1em;
		}
	}

	.heading-2 {
		font-size: 1.8em;
		line-height: 1.15em;
		@media(min-width:@x-small) { }
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 2.45em;
		}
		@media(min-width:@large) {
			font-size: 2.6em;
		}
	}

	.heading-3 {
		font-size:1.6em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 2.2em;
		}
		@media(min-width:@large) {}
	}

	.heading-4 {
		font-size:1.6em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 2em;
			line-height: 1em;
		}
		@media(min-width:@large) {}
	}

	.heading-5 {
		font-size:1.4em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 1.8em;
		}
		@media(min-width:@large) {}
	}

	.heading-6 {
		font-size:1.2em;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 1.6em;
			line-height: 1.2em;
		}
		@media(min-width:@large) {}
	}


	.subheading {
		font-size: .9em;
		color: @red;
		@media(min-width:@x-small) {}
		@media(min-width:@small) {}
		@media(min-width:@medium) {
			font-size: 1em;
		}
		@media(min-width:@large) {}
	}


// user-editable content sections
// for any CMS-editable body text areas, apply '.text-main' and style the basic
// elements specifically — this reduces effect of global styles on components

	.text-main {

		// basic heading styles
		h1, h2, h3, h4, h5, h6 {

		}

		p {
			font-size: 1.2em;
		}

		ul {
			margin-left: 0;
			padding-left: 1em;
		}

		// list styles
		li {
			margin-bottom:.75em;
		}
	}


// modifiers

.text-large {
	font-size: 1.2em !important;
}

.text-white {
	color:white;
}

.text-red {
	color: @red;
}