.school-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -0.25em;
    margin-top: -0.25em;
    & li {
        padding: 0.25em 0 0 0.25em;
    }
}

.schools {
    margin-left: -2em;
}

.school {
    padding-left: 2em;
}

.school--inner {
    border-radius: @radius-small;
}