
// default text link styles

a { 
    color: @red;
}

a:hover {}

a:active {}
