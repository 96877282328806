
// sets horizontal page spacing across all breakpoints

.page-width {	

	// center by default
	margin-left:auto;
	margin-right:auto;

	// 1em from viewport edge on mobile
	min-width:(@body-min-width - 2em);
	
	max-width:90vw;
	@media(min-width:@small) {}
	@media(min-width:@medium) {}
	@media(min-width:@large) {}	
}


.page-width--narrow {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:75vw }
}

.page-width--extra-narrow {
	&:extend(.page-width);
	@media(min-width:@medium) { max-width:60vw }
}
