.latest-content {
    background: @gradient;
    background: @gradient2;
    width: 80%;
    padding: 4em 4em 8em 5vw;
    border-radius: 0 2em 2em 0;
    @media (min-width: @medium) {
        width: 40%;
        margin-bottom: 5em;
        padding: 4em 5em 4em 5vw;

    }
}

.latest-articles {
    margin: -4em 0 0 0;
    width: 100%;
    @media (min-width: @medium) {
        width: 65%;
        margin: 4em 0 0 -5%;
    }
}

.latest-background {
    position: absolute;
    width: 100%;
    background: @gray;
    z-index: -1;
    height: 70%;
    top: 30%;
    @media (min-width: @medium) {
        height: 60%;
        top: 40%;
    }
}

.article a {
    text-decoration: none;
    color: @darkgray;
}

.articles .slick-slide {
    margin-right: 20px;
    border-radius: @radius-small;
    overflow: hidden;
}

.article-meta {
    color: grey;
    font-size: .9em;
}

.article-meta div {
    padding-right: 3px;
    display: inline-block;
    &::after {
        content: '';
        margin: -1px 3px 0 8px;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: @red;
        display: inline-block;
        position: relative;
        top: -1px;
    }
    &:last-of-type::after {
        display: none;
    }
}

.article-meta span {
    display: inline-block;
    &::after {
        content: ", ";
    }
    &:last-of-type::after {
        display: none;
    }
}

.slick-list {
    margin-left: 5vw !important;
    @media (min-width: @medium) {
        margin-left: 0 !important;
    }
}

.slick-dots {
    list-style: none;
    margin: 20px 0 0 0;
    text-align: center;
    & li {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: @midgray;
        margin: 0px 5px;
        border-radius: 100%;
    }
}

.slick-dots li.slick-active {
    background: @red;
}

.slick-dots button {
    display: none;
}

/* .latest-content {
    background: @gradient;
    background: @gradient2;
    border-radius: 0 2em 2em 0;
    padding-left: 5vw;
    padding-right: 8vw;
    width: 40%;
    margin-bottom: 5em;
}

.latest-articles {
    width: 65%;
    margin-left: -5%;
    margin-top: 3em;
}

.articles .slick-slide {
    margin-right: 20px;
    border-radius: @radius-small;
    overflow: hidden;
}

.slick-dots {
    list-style: none;
    margin: 20px 0 0 0;
    text-align: center;
    & li {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #F8F8F6;
    margin: 0px 5px;
    border-radius: 100%;
}

.slick-dots li.slick-active {
    background: red;
}

.slick-dots button {
    display: none;
}

*/