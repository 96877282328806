// background

.fill-gray {
	background: @gray;
}

.fill-white {
	background: @white;
}

// text

.text-center { text-align:center }
.text-left { text-align:left }


// li

.reset-list {
	margin:0; padding:0;
	> li { list-style-type:none }
}


// position

.relative { position:relative }

.absolute-fit {
	position: absolute;
	top:0; right:0; bottom:0; left:0;
}


// show/hide

.hide { display:none !important }
.show { display:block !important }

.for-breakpoints({
    .hide\@@{breakpoint} { display:none !important }
    .show\@@{breakpoint} { display:block !important }
});


// container

.first > :first-child { margin-top:0 !important }
.last > :last-child { margin-bottom:0 !important }

.first-last {
	> :first-child { margin-top:0 !important }
	> :last-child { margin-bottom:0 !important }
}


// object fit

.object-fit, .object-fit--cover {
	object-fit:cover;
	object-position:50% 50%;
	width:100%; height:100%;
}

.object-fit--contain {
	&:extend(.object-fit);
	object-fit:contain;
}

.object-fit--absolute {
	&:extend(.object-fit);
	&:extend(.absolute-fit);
}


// Radius 

.radius {
	overflow: hidden;
	border-radius: @radius;
}

.radius--small {
	overflow: hidden;
	border-radius: @radius-small;
}

// Slider


.slick-list.draggable:active {
	cursor: grabbing;
}

.slick-dots li {
	cursor: pointer;
}