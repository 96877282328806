.banner {
    border-radius: 0 0 0 @radius;
    overflow: hidden;
    @media (min-width: @medium) {
        max-height: 450px;
    }
}

.banner img {
    max-height: 300px;
    @media (min-width: @medium) {
        max-height: 450px;
    }
}