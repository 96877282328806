.form-cont {
    overflow: hidden;
    border-radius: @radius-small;
}

.nf-form-title, .nf-form-fields-required, .nf-field-label {
    display: none;
}

.textbox-container, .email-container {
    clear: none !important;
    float: left;
    @media (min-width: @medium) {
        width: calc(50% - 1em);
    }
}

.textbox-container {
    margin-right: 2em;
}

.textbox-container .nf-element,
.email-container .nf-element {
    height: 40px;
}

.nf-element, 
.nf-element::placeholder {
    font-size: 1em;
    resize: none;
    font-family: @body !important;
}

.submit-wrap .nf-element {
    background: @red !important;
    color: #fff;
    padding: 1.4em 2.5em;
    font-size: 1.1em;
    line-height: 0;
    font-family: @main !important;
    transition: .3s backgground ease-in-out;
    &:hover {
        background: @darkred;
    }
}

.nf-element {
    padding: 1em .5em;
    border-radius: 0.5em;
    border: 0;
}

.nf-element:disabled {
    opacity: .7;
    cursor: not-allowed;
}

.nf-form-errors {
    float: left;
    width: 100%;
}
