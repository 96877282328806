.site-header {
    background: @gradient;
    background: @gradient2;
    padding: 2em 0;
}

.home .site-header {
    @media (min-width: @medium) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background: transparent !important;
    }
}

.brand {
    width: 220px;
    margin-right: 10px;
    margin-top: -3px;
    @media (min-width: @medium) {
        width: 280px;
        margin-right: 40px;
    }
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: @main;
}

.menu li {
    line-height: 1em;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
}

.menu li {
    display: inline-block;
    margin: 0 20px;
}

.menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
}

.site-menu__dropdown {
    display: none;
}

.site-menu__link::after {
    display: block;
    content: "";
    float: left;
    height: 2px;
    width: 1%;
    background: transparent;
	transition: 0.3s all ease-in-out;
	position: relative;
	right: 0;
    margin-top: 5px;
}

.site-menu__link:hover::after {
    width: 50%;
    background: #fff;
}

.menu-active::after, .menu-active:hover::after {
    width: 100%;
    background: #fff;
}

.site-header .button-fill {
    padding: 0.7em 1.4em;
}

.menu-support {
    top: -4px;
    position: relative;
}

// Sub menu

@media (min-width: 1120px) {
    .site-menu__item {
        position: relative;
    }

    .site-menu__dropdown {
        position: absolute;
        left: 0;
        z-index: 99;
        padding: 1em 0 0;
    }

    .site-menu__dropdown__item {
        background: @gradient;
        background: @gradient2;
        width: 200px;
        padding: 1em;
    }

    .site-menu__item:hover .site-menu__dropdown {
        display: block;
    }
}


@media (max-width: 1120px) {
    .site-menu, .menu-contact, .menu-support {
        display: none !important;
    }
}


// Mobile Icon 

#nav-btn {
    width: 40px;
    height: 32px;
    position: relative;
    top: 0;
    margin-left: 20px;
    float: right;
    transform: rotate(0deg) scale(0.8);
    transition: .5s ease-in-out;
    display: none;
    cursor: pointer;
    z-index: 99;
    margin: 0 !important;
    top: 3px;
    & span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: @white;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
    & span:nth-child(1) {
        top: 0px;
    }
    & span:nth-child(2) {
        top: 11px;
    }
    & span:nth-child(3) {
        top: 22px;
    }
    @media (max-width: 1120px) {
        display: block;
    }
}

#nav-btn.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-btn.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-btn.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

#masthead .button-fill {
    background: @darkred !important;
    &:hover {
        opacity: .8;
    }
}



// Mobile 


.right-nav {
    position: relative;
    z-index: 150;
}

.mobile-menu {
    width: 0%;
    height: 100vh;
    background: @gradient;
    background: @gradient2;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s all ease-in-out;
    display: none;
    &.open {
        width: 100vw;
        display: block;
        @media (min-width: 1121px) {
            display: none;
        }
    }
}

.mobile-menu__cont {
    height: 100%;
    font-size: 1.4em;
    text-align: center;
    & a {
        color: @white;
        text-decoration: none;
        margin-bottom: .6em;
        display: block;
        &:after {
            display: none !important;
        }
    }
}