#map-canvas {
    height: 400px;
    width: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 40px;
    z-index: 10;
    @media (min-width: @medium) {
        height: 400px;
        width: 600px;
    }
    @media (min-width: @large) {
        width: 800px;
    }
}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc, .gm-fullscreen-control, .gm-style-mtc, .gm-svpc {
display:none;
}
.gmnoprint div  {
}

.map-container {
    position: relative;
}

.gm-style-iw-a * {
    font-family: @main;
}