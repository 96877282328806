.hero {
    background: @gradient;
    background: @gradient2;
    border-radius: 0 0 0 @radius;
    @media (min-width: @medium) {
        padding: 20vh 0 11vh;
    }
}

.hero-thumb {
    height: 40vh !important;
    @media (min-width: @medium) {
        width: 45% !important;
        position: absolute;
        top: 0;
        right: 0;
        height: 100% !important;
    }
}

.hero-content {
    padding: 2em 0 3em;
    @media (min-width: @medium) {
        width: 55%;
        padding-right: 5%;
    }
}

.hero-content p {
    font-size: 1.3em;
}

.hero-content a {
    font-size: 1.15em;
}

.hero-thumb__overlay {
    @media (min-width: @medium) {
        position: absolute;
        top: 0;
        width: 100%;
        right: 0;
        background: linear-gradient(180deg, rgba(0,0,0,.6) 10%, rgba(0,0,0,0) 61%);
        height: 30%;
    }
}

/*
.hero-content {
    width: 50%;
    padding-right: 5%;
}

.hero-thumb {
    width: 45% !important;
    right: 0;
    left: auto !important;
}
*/