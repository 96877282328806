.card__inner {
    height: 100%;
}

.col-6\@medium .card__image {
    height: 300px;
    @media (min-width: @medium) {
        height: 350px;
    }
}

.col-4\@medium .card__image {
    height: 300px;
    @media (min-width: @medium) {
        height: 250px;
    }
}