/* This stylesheet is used to style the public-facing components of the plugin. */

/* START: Recommended Isotope styles */

/* Isotope filtering */

.isotope-item {
    z-index: 2;
  }
  
  .isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
  }
  
  /* Isotope CSS3 transitions */
  
  .isotope,
  .isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
       -moz-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
         -o-transition-duration: 0.8s;
            transition-duration: 0.8s;
  }
  
  .isotope {
    -webkit-transition-property: height, width;
       -moz-transition-property: height, width;
        -ms-transition-property: height, width;
         -o-transition-property: height, width;
            transition-property: height, width;
  }
  
  .isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
       -moz-transition-property:    -moz-transform, opacity;
        -ms-transition-property:     -ms-transform, opacity;
         -o-transition-property:      -o-transform, opacity;
            transition-property:         transform, opacity;
  }
  
  /* disabling Isotope CSS3 transitions */
  
  .isotope.no-transition,
  .isotope.no-transition .isotope-item,
  .isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
       -moz-transition-duration: 0s;
        -ms-transition-duration: 0s;
         -o-transition-duration: 0s;
            transition-duration: 0s;
  }
  
  /* END: Recommended Isotope styles */
  
  /* Plugin-specific styles */
  
  #filters {
      list-style: none;
      margin: 0 0 2.5em 0;
      padding: 0;
      justify-content: center;
  }
  
  #filters li {
      display: flex;
      margin-right: 1em;
      align-items: center;
      &:after {
         content: "";
         float: left;
         width: 6px;
         height: 6px;
         border-radius: 100%;
         background: @red;
         margin-left: 1em;
      }
      &:last-of-type {
         margin-right: 0;
         &:after {
            display: none;
         }
      }
  }
  
  #filters li a {
      text-decoration: none;
      padding: 0;
      display: block;
      color: @darkgray;
      font-family: @main;
      font-weight: 500;
      border-bottom: 2px solid transparent;
      transition: .3s all ease-in-out;
  }

  .filter-active a {
     border-bottom: 2px solid @red !important;
  }
  
  .iso-container {
     clear: both;
  }
  
  #iso-loop {
     display: none;
     list-style: none;
     padding: 0;
  }
  
  .iso-post {
     -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 4em !important;
  }

  .iso-post__cont {
   border-radius: @radius-small;
   overflow: hidden;

  }
  
  h2.iso-title a {
      text-decoration: none;
  }
  
  .iso-thumb {
      width: auto;
      padding-bottom: .5em;
  }
  
  .iso-thumb img {
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
  
  .more-iso-posts {
     display: none;
  }
  
  .iso-posts-loading {
     margin: 0 auto;
     text-align: center;
  }
  
  .iso-posts-loading img {
     display: block;
     margin: 0 auto;
     box-shadow: none !important;
     border: 0;
  }
  

  #iso-loop a {
   text-decoration: none;
}