

@grid-gutter: 4rem;
@grid-gutter-narrow: 2rem;
@grid-columns: 12;


.grid {
	display:flex;
	flex-wrap:wrap;
}

.space-between {
	justify-content: space-between;
}

.flex-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

[class*=col-] { width:100% }

.grid--gutter--horizontal {
	margin-left:-@grid-gutter;
	> [class*=col-] { padding-left:@grid-gutter }
}

.grid--gutter--vertical {
	margin-top:-@grid-gutter;
	> [class*=col-] { padding-top:@grid-gutter }
}

.grid--gutter--horizontal-narrow {
	margin-left:-@grid-gutter-narrow;
	> [class*=col-] { padding-left:@grid-gutter-narrow }
}

.grid--gutter--vertical-narrow {
	margin-top:-@grid-gutter-narrow;
	> [class*=col-] { padding-top:@grid-gutter-narrow }
}

.grid--gutter {
	.grid--gutter--horizontal;
	.grid--gutter--vertical;
}

.grid--gutter-narrow {
	.grid--gutter--horizontal-narrow;
	.grid--gutter--vertical-narrow;
}

.col-flex { display:flex }


// generates .col-x classes

.for(@grid-columns, {
	.col-@{i} { width: (100% / @grid-columns * @i) }
});


// generates .col-x@breakpoint classes

.for-n-per-breakpoint(@grid-columns, {
	.col-@{i}\@@{breakpoint} { width: (100% / @grid-columns * @i) }
});