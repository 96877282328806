
// debug
@debug: false;

// Colo
@red: #D81E31;
@darkred: #B51929;
@white: #ffffff;
@gray: #F8F8F6;
@midgray: #C0C0BE;
@darkgray: #3C3C3B;

// border
@radius-small: 1.5em;
@radius: 3em;

@gradient: rgb(181,25,41);
@gradient2: linear-gradient(90deg, rgba(181,25,41,1) 27%, rgba(213,32,50,1) 61%);

// type
@main: "Archer", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
@body: "brandon-grotesque", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;

// easing
@ease: cubic-bezier(0.190, 1.000, 0.220, 1.000);
@ease2: cubic-bezier(.24,.97,.4,.96);
