.team-member {
    border-radius: @radius-small;
    overflow: hidden;
    max-width: 350px;
    margin: 0 auto;
    cursor: pointer;
    @media (min-width: @medium) {
        max-width: inherit;
        margin: unset;
    }
}

.team-member__image {
    height: 200px;
}

.team-member .heading-6 {
    font-size: 1.4em;
}

.team-member__content, .team-member__image {
    opacity: 1;
    transition: .3s all ease-in-out;
}

.team-member:hover .team-member__content,
.team-member:hover .team-member__image {
    opacity: 0;
}

.team-member__card {
    visibility: hidden;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transition: .3s all ease-in-out;
    opacity: 0;
}

.team-member:hover .team-member__card {
    visibility: visible;
    transition-delay: .3s;
    opacity: 1;
}
