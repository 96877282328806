.site-info p {
    font-size: .9em;
    opacity: .5;
}

.single-post .site-footer {
    margin-top: 4em;
}

.site-info a {
    color: #fff;
    text-decoration: none;
}

.social img {
    width: 30px;
    margin-right: 10px;
}