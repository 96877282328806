.breadcrumbs__item {
    display: inline-block;
    margin-right: .5em;
    &::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url('/wp-content/themes/next-step/assets/img/breadcrumb-arrow-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
    &:last-of-type::after {
        display: none;
    }
}
.breadcrumbs__link {
    color: @red !important;
    font-family:@main;
    margin-right: .5em;
    text-decoration: none;
}