.text-image {
    border-radius: @radius;
    overflow: hidden;
}

.text-image__image {
    height: 40vh;
    @media (min-width: @medium) {
        height: auto;
    }
}

.text-image__content {
    padding: 2em 2em 3em;
    @media (min-width: @medium) {
        padding: 4em 4em 4.5em;
    }
}


// Flip rows

.flip--row .text-image__image {
    @media (min-width: @medium) {
        order: 2;
    }
}

.flip--row .text-image__content {
    @media (min-width: @medium) {
        order: 1;
    }
}