.testimonial {
    overflow: hidden;
    border-radius: @radius;
}

.testimonial__image {
    min-height: 280px;
    @media (min-width: @small) {
        min-height: 350px;
    }
    @media (min-width: @medium ) {
        min-height: inherit;
    }
}